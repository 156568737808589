<template>
  <div class="holzkraftWrap"
       v-if="project && project.plugins && project.plugins.holzkraft && project.plugins.holzkraft[0] && (mode === 'enter' || mode ==='show') ">
    <template v-if="mode === 'enter'">
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label>Projektumsatz</label>
            <input type="number" class="form-control" v-model="project.plugins.holzkraft[0].projektGesamtkosten"/>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-12">
          <div class="form-group">
            <label>kWel</label>
            <input type="number" class="form-control" v-model="project.plugins.holzkraft[0].kwel"/>
          </div>
        </div>
        <div class="col-lg-6 col-12">
          <div class="form-group">
            <label>Anlage</label>
            <Multiselect :options="anlagen"
                         :multiple="false"
                         :searchable="false"
                         :close-on-select="true"
                         :show-labels="false"
                         :canClear="true"
                         :canDeselect="true"
                         valueProp="value"
                         class="mb-3"
                         :label="'name'"
                         locale="de"
                         :placeholder="'Anlage wählen'"
                         :showNoOptions="false"
                         v-model="project.plugins.holzkraft[0].anzahlAnlagen"
                         openDirection="bottom"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-12">
          <div class="form-group">
            <label>€ pro kWel</label>
            <input type="number" class="form-control" v-model="project.plugins.holzkraft[0].preisProKw"/>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-12">
          <div class="form-group">
            <label>Angabe in %</label>
            <input type="number" class="form-control" v-model="project.plugins.holzkraft[0].abschlusswahrscheinlichkeit"
                   min="0" max="100"/>
          </div>
        </div>
        <div class="col-lg-6 col-12">
          <div class="form-group">
            <label class="me-3">Gebrauchtanlage?</label>
            <div class="btn-group btn-group-yesno" role="group">
              <input type="radio" class="btn-check" name="gebrauchtanlage" id="gebrauchtanlage_yes" value="1"
                     v-model="project.plugins.holzkraft[0].gebrauchtanlage">
              <label class="btn btn-outline-primary" for="gebrauchtanlage_yes">Ja</label>
              <input type="radio" class="btn-check" name="gebrauchtanlage" id="gebrauchtanlage_no" value="0"
                     v-model="project.plugins.holzkraft[0].gebrauchtanlage">
              <label class="btn btn-outline-danger" for="gebrauchtanlage_no">Nein</label>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12">
          <div class="form-group">
            <label>Land</label>
            <input type="text" class="form-control" v-model="project.plugins.holzkraft[0].lieferanschrift_land"/>
          </div>
        </div>
      </div>
    </template>
    <template v-if="mode === 'show'">
      <div class="row mt-3">
        <div class="col-lg col-md-6 col-12">
          <div class="form-group">
            <label>Projektumsatz:&nbsp;</label>
            <strong>
              {{ project.plugins.holzkraft[0].projektGesamtkosten }}</strong> €
          </div>
        </div>
        <div class="col-lg col-md-6 col-12">
          <div class="form-group">
            <label>kWel-Gesamt:&nbsp;</label><strong>{{ project.plugins.holzkraft[0].kwel }}</strong>
          </div>
        </div>
        <div class="col-lg col-md-6 col-12">
          <div class="form-group">
            <label>Anlage:&nbsp;</label><strong>
            {{ project.plugins.holzkraft[0].anzahlAnlagen }}</strong>
          </div>
        </div>
        <div class="col-lg col-md-6 col-12">
          <div class="form-group">
            <label>Angabe in %:&nbsp;</label><strong>
            {{ project.plugins.holzkraft[0].abschlusswahrscheinlichkeit }}%</strong>
          </div>
        </div>
        <div class="col-lg  col-md-6 col-12">
          <div class="form-group">
            <label class="me-3">Gebrauchtanlage?</label>
            <template v-if="project.plugins.holzkraft[0].gebrauchtanlage === 1"><span tooltip="Ja"><i
              class="fa-duotone fa-circle-check green"></i></span></template>
            <template v-else>
              <i class="fa-duotone fa-circle-xmark red"></i>
            </template>
          </div>
        </div>
      </div>
      <!-- expand if to all values with or-->
      <div class="form-group" v-if="project.plugins.holzkraft[0].lieferanschrift_land">
        <label class="color">Lieferaddresse</label>
        <small>
          {{ project.plugins.holzkraft[0].lieferanschrift_land }}
        </small>
      </div>
    </template>
  </div>
  <template v-if="mode === 'listSort'">
    <div class="draftLineWrap projectLine fullFromPluginHolz">
      <div class="lineitem l-country"><span :class="(localSortDirections.lieferanschrift_land) ? 'active' : ''"
                                            @click="emitSort('lieferanschrift_land')">
      <i v-if="localSortDirections.lieferanschrift_land === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="localSortDirections.lieferanschrift_land === 'desc'" class="fa-duotone fa-arrow-down"></i>
      Land</span></div>
      <div class="lineitem l-projectname">
        <span :class="(localSortDirections.title) ? 'active' : ''"
              @click="emitSort('title')">
      <i v-if="localSortDirections.title === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="localSortDirections.title === 'desc'" class="fa-duotone fa-arrow-down"></i>
      Projektname</span>
        </div>

      <div class="lineitem l-start">Start-Datum</div>
      <div class="lineitem l-title">Name</div>
      <div class="lineitem fromPlugin l-anlagen"><span :class="(localSortDirections.anzahlAnlagen) ? 'active' : ''"
                                                       @click="emitSort('anzahlAnlagen')">
      <i v-if="localSortDirections.anzahlAnlagen === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="localSortDirections.anzahlAnlagen === 'desc'" class="fa-duotone fa-arrow-down"></i>
      Anlage</span>
      </div>
      <div class="lineitem fromPlugin l-kwel"><span @click="emitSort('kwel')"
                                                    :class="(localSortDirections.kwel) ? 'active' : ''">
      <i v-if="localSortDirections.kwel === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="localSortDirections.kwel === 'desc'" class="fa-duotone fa-arrow-down"></i>
      kWel</span></div>
      <div class="lineitem fromPlugin l-ppkw"><span @click="emitSort('preisProKw')"
                                                    :class="(localSortDirections.preisProKw) ? 'active' : ''">
      <i v-if="localSortDirections.preisProKw === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="localSortDirections.preisProKw === 'desc'" class="fa-duotone fa-arrow-down"></i>
      € / kWel</span></div>
      <div class="lineitem fromPlugin l-pgesamt"><span @click="emitSort('projektGesamtkosten')"
                                                       :class="(localSortDirections.projektGesamtkosten) ? 'active' : ''">
      <i v-if="localSortDirections.projektGesamtkosten === 'asc'" class="fa-duotone fa-arrow-up"></i>
      <i v-if="localSortDirections.projektGesamtkosten === 'desc'" class="fa-duotone fa-arrow-down"></i>
      € gesamt</span></div>
      <div class="lineitem l-abschluss">%</div>
      <div class="lineitem l-abschlussmath text-end">€ in %</div>
      <div class="lineitem l-actions"></div>
      <div class="lineitem l-tags">Tags</div>
      <div class="lineitem l-avatar ml-auto">Betreuer</div>
      <div class="lineitem last ms-2"> </div>
    </div>
  </template>
  <template v-if="mode === 'listHeadline'">
    <template v-if="project && project.plugins && project.plugins.holzkraft[0]">
      <div class="draftLineWrap projectLine fullFromPluginHolz">
        <div class="lineitem l-country">{{project.plugins.holzkraft[0].lieferanschrift_land}}</div>
        <div class="lineitem l-projectname" style="cursor: pointer;">{{ project.title }} <template v-if="project.description">
        <i class="fa-duotone fa-info-circle" @click="showDescription(project.description)"></i>

        </template>
        </div>

        <div class="lineitem l-start">{{ $filters.formatDate(project.start) }}</div>
        <div class="lineitem l-title">
          <span v-if="project.c_company || (project.c_firstname && project.c_lastname)">
            <template v-if="project.c_company">{{ project.c_company }}<br></template>
            {{ project.c_firstname }} {{ project.c_lastname }}
          </span>
        </div>

        <div class="lineitem l-anlagen fromPlugin" style="text-align: center;">
          <small>{{ project.plugins.holzkraft[0].anzahlAnlagen }}</small>
        </div>
        <div class="lineitem l-kwel fromPlugin">{{ project.plugins.holzkraft[0].kwel }}</div>
        <div class="lineitem l-ppkw fromPlugin">{{ project.plugins.holzkraft[0].preisProKw }}</div>
        <div class="lineitem l-pgesamt fromPlugin">{{ $filters.priceEuro(project.plugins.holzkraft[0].projektGesamtkosten) }}</div>
        <div class="lineitem l-abschluss"><template v-if="project.plugins.holzkraft[0].abschlusswahrscheinlichkeit && project.plugins.holzkraft[0].abschlusswahrscheinlichkeit > 0">{{ project.plugins.holzkraft[0].abschlusswahrscheinlichkeit }}%</template></div>
<div class="lineitem l-abschlussmath text-end"><template v-if="project.plugins.holzkraft[0].euro_nach_prozent && project.plugins.holzkraft[0].euro_nach_prozent > 0">{{ $filters.priceEuro(project.plugins.holzkraft[0].euro_nach_prozent) }}</template></div>
        <div class="lineitem l-actions">
          <span class="iconAction btn btn-sm btn-outline-secondary"
                @click="getNotices(project.id);addProjectNotice = true;notice.project_id = project.id"
                tooltip="Notiz erstellen">
      <i class="fa-note-sticky fa-duotone"></i><span v-if="project._countNotices > 0"> <span class="incNumber">{{
              project._countNotices
            }}</span></span>
    </span>

        </div>
        <div class="lineitem l-tags"
             >
          <template v-if="project && project.tagsFormatted && project.tagsFormatted.length > 0">
        <span v-for="tag in project.tagsFormatted" :key="tag.id" class="badge badge-primary badge-xs me-1"
              :style="'background-color:'+tag.color">
          <template v-if="tag.icon"><i class="fa-duotone" :class="'fa-'+tag.icon"></i></template>
          {{ tag.name }}</span>
          </template>
        </div>
        <div class="lineitem l-avatar ml-auto">
          <img style="max-width: 40px" :src="$filters.employeeIcon(getEmployees, project.employee_id, 'id')"
               class="sketchAvatar" v-if="project.employee_id">
        </div>
        <div class="lineitem last ms-2">
 <i class="fa-duotone fa-chevrons-up" @click="setDraft(project)"></i>
        </div>
      </div>
      <modal v-if="addProjectNotice">
        <div class="modal fade show" tabindex="-1"
             style="display: block;">
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">
                  {{ $t("globals.newNote") }}
                </h5>
                <button type="button" style="margin-left: auto;" class="btn btn-outline-primary" @click="postNotice()"><i
                  class="fa-duotone fa-floppy-disk"></i> {{ $t("globals.save") }}
                </button>
                <button type="button" class="btn-close ms-3"
                        @click="addProjectNotice = false; notice.project_id = null;"></button>
              </div>
              <div class="modal-body">
                <div class="form-group">
                  <label>{{ $t("globals.note") }}</label>
                  <Editor2 v-model="notice.notice_message"/>
                </div>
                <template v-if="notices && notices.length > 0">
                  <div class="noticesContainer">
                    <template v-for="n in notices">
                      <div class="noticeItem" v-if="n.notice_type ==='project' && n.affect_id === project.id">
                        <div class="noticeHeader mb-2">
                          <img :src="$filters.employeeIcon(getEmployees, n.notice_author, 'u_id')" class="sketchAvatar me-2">
                          <span class="noticeDate"><small>{{ $filters.formatDateTime(n.notice_date) }}</small></span>
                        </div>
                        <div class="noticeMessage" v-html="n.notice_message"></div>
                        <hr>
                      </div>
                    </template>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </modal>
      <modal v-if="showDescriptionModal">
        <div class="modal fade show" tabindex="-1"
             style="display: block;">
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">
                  {{ $t("globals.description") }}
                </h5>
                <button type="button" class="btn-close ms-3"
                        @click="showDescriptionModal = false; localDescription='';"></button>
              </div>
              <div class="modal-body">
                <div v-html="localDescription">

                </div>
              </div>
            </div>
          </div>
        </div>

      </modal>
    </template>
  </template>
  <template
    v-if="mode === 'editCustomer2auskommentiert' && $store && $store.state && $store.state.activeItems && $store.state.activeItems.customer && $store.state.activeItems.customer.projects && $store.state.activeItems.customer.projects[0] && $store.state.activeItems.customer.projects[0].plugins && $store.state.activeItems.customer.projects[0].plugins.holzkraft && $store.state.activeItems.customer.projects[0].plugins.holzkraft[0]">
    <div class="row">
      <div class="col-6">
        <div class="form-group">
          <label for="projectTitle">Projektname</label>
          <input type="text" class="form-control" id="projectTitle"
                 v-model="$store.state.activeItems.customer.projects[0].title">
        </div>
      </div>
      <div class="col-6">
        <div class="form-group" v-if="getConfigs">
          <label>Projektstatus</label>
          <select style="width: 100%;" name="" id="" v-model="$store.state.activeItems.customer.projects[0].state_id">
            <option v-for="info in getConfigs.project_states" :value="info.id">{{ info.title }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <div class="form-group">
          <label for="projectTitle">Geplante erste Anzahlung</label>
          <VueDatePicker v-model="$store.state.activeItems.customer.projects[0].start" model-type="yyyy-MM-dd"
                         format="dd.MM.yyyy" placeholder="Projektstart" :auto-apply="true" :close-on-auto-apply="true"
                         locale="de" :enable-time-picker="false" cancel-text="X" select-text="Wählen" :teleport="true">
          </VueDatePicker>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label>Letzte Auslieferung</label>
          <VueDatePicker v-model="$store.state.activeItems.customer.projects[0].end" model-type="yyyy-MM-dd"
                         format="dd.MM.yyyy" placeholder="Projektende" :auto-apply="true" :close-on-auto-apply="true"
                         locale="de" :enable-time-picker="false" cancel-text="X" select-text="Wählen" :teleport="true">
          </VueDatePicker>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <label>Projektumsatz</label>
          <input type="number" class="form-control"
                 v-model="$store.state.activeItems.customer.projects[0].plugins.holzkraft[0].projektGesamtkosten"/>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-12">
        <div class="form-group">
          <label>kWel-Gesamt</label>
          <input type="number" class="form-control"
                 v-model="$store.state.activeItems.customer.projects[0].plugins.holzkraft[0].kwel"/>
        </div>
      </div>
      <div class="col-lg-6 col-12">
        <div class="form-group">
          <label>Anlagentyp</label>
          <Multiselect :options="anlagen"
                       :multiple="true"
                       :searchable="false"
                       :close-on-select="true"
                       :show-labels="false"
                       :canClear="true"
                       :canDeselect="true"
                       valueProp="value"
                       class="mb-3"
                       :label="'name'"
                       locale="de"
                       :placeholder="'Anlage wählen'"
                       :showNoOptions="false"
                       v-model="$store.state.activeItems.customer.projects[0].plugins.holzkraft[0].anzahlAnlagen"
                       openDirection="bottom"
                       mode="tags"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-12">
        <div class="form-group">
          <label>Preis / kW</label>
          <input type="number" class="form-control"
                 v-model="$store.state.activeItems.customer.projects[0].plugins.holzkraft[0].preisProKw"/>
        </div>
      </div>
      <div class="col-lg-6 col-12">
        <div class="form-group">
          <label>Abschlusswahrscheinlichkeit in %</label>
          <input type="number" class="form-control"
                 v-model="$store.state.activeItems.customer.projects[0].plugins.holzkraft[0].abschlusswahrscheinlichkeit"
                 min="0" max="100"/>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-12">
        <div class="form-group">
          <label class="me-3">Gebrauchtanlage?</label>
          <div class="btn-group btn-group-yesno" role="group">
            <input type="radio" class="btn-check" name="gebrauchtanlage" id="gebrauchtanlage_yes" value="1"
                   v-model="$store.state.activeItems.customer.projects[0].plugins.holzkraft[0].gebrauchtanlage">
            <label class="btn btn-outline-primary" for="gebrauchtanlage_yes">Ja</label>
            <input type="radio" class="btn-check" name="gebrauchtanlage" id="gebrauchtanlage_no" value="0"
                   v-model="$store.state.activeItems.customer.projects[0].plugins.holzkraft[0].gebrauchtanlage">
            <label class="btn btn-outline-danger" for="gebrauchtanlage_no">Nein</label>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-12">
      </div>
    </div>
    <div class="row">
      <div class="col-12"><p><strong>Lieferanschrift</strong></p></div>
    </div>
    <div class="row">
      <div class="col-lg-3 col-md-6 col-sm-12">
        <div class="form-group">
          <label>Land</label>
          <input type="text" class="form-control"
                 v-model="$store.state.activeItems.customer.projects[0].plugins.holzkraft[0].lieferanschrift_land"/>
        </div>
      </div>
    </div>
  </template>
</template>
<script>
import {mapGetters} from "vuex";
import VueDatePicker from '@vuepic/vue-datepicker';
import Multiselect from "@vueform/multiselect";
import vuex from "vuex";
import axios from "axios";
import Editor2 from "@/components/Editor2.vue";

export default {
  name: "HolzkraftProjects",
  emits: ['sort'],
  components: {
    Editor2,
    VueDatePicker, Multiselect
  },
  methods: {
    emitSort(clickedType) {
      // Reset all sort directions to the default state except for the clickedType
      for (let type in this.localSortDirections) {
        if (type !== clickedType) {
          this.localSortDirections[type] = '';
        }
      }

      // Toggle the clicked type's sort direction based on your logic
      if (this.localSortDirections[clickedType] === '') {
        this.localSortDirections[clickedType] = 'desc';
      } else if (this.localSortDirections[clickedType] === 'desc') {
        this.localSortDirections[clickedType] = 'asc';
      } else {
        this.localSortDirections[clickedType] = '';
      }

      // Emit the clicked type and direction
      this.$emit('sort', { type: clickedType, direction: this.localSortDirections[clickedType] });
    },
    showDescription(description) {
      this.localDescription = description;
      this.showDescriptionModal = true;
    },
    setDraft(project) {
      this.$store.commit('closeProject');
      // next tick
      this.$nextTick(() => {
        this.$store.dispatch("setActiveProject", project);
        this.$router.push({name: 'ProjectDetails', params: {id: project.id}})
        this.scrollToTop();
      });
    },
    scrollToTop() {
      $("html, body").animate({scrollTop: 0});
    },
    getNotices(project_id) {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.getters.getApiUrl + "project/" + project_id + "/notices", {headers}).then((r) => {
        this.notices = r.data.result.data;
      }).catch((e) => {
        this.toast.error(e.response.data.result.message);
      });
    },
    postNotice() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      let postContent = {message: this.notice.notice_message}
      axios.post(this.$store.state.apiUrl + "project/" + this.notice.project_id + "/notice/", postContent, {headers}).then((r) => {
        this.addDocNotice = false;
        this.notice.notice_message = '';
        this.notice.project_id = null;
        this.toast.success('Notiz wurde erfolgreich gespeichert');
      }).catch((e) => {
        this.toast.error(e.response.data.result.message);
      });
    },
    showProcess(process_id) {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.getters.getApiUrl + "process/" + process_id, {headers}).then((r) => {
        this.currentProcess = r.data.result.data;
      }).catch((e) => {
        this.$store.dispatch("handleApiResponse", e.response);
      });
    },
  },
  data() {
    return {
      states: ['prepare', 'offer', 'order', 'progress', 'finished', 'deleted'],
      localSortDirections: {...this.sortDirections},
      anlagen: [
        {"name": "HKA 35", "value": "HKA 35"},
        {"name": "HKA 50", "value": "HKA 50"},
        {"name": "HKA 70", "value": "HKA 70"},
        {"name": "HKA 70 Prozess", "value": "HKA 70 Prozess"},
        {"name": "HKA 300", "value": "HKA 300"},
        {"name": "HKA 700", "value": "HKA 700"},
        {"name": "HV 70", "value": "HV 70"},
        {"name": "HV 70 Direktgas", "value": "HV 70 Direktgas"},
        {"name": "Trockne", "value": "Trockne"}
      ],
      notice: {
        project_id: null,
        notice_message: ''
      },
      notices: [],
      addProjectNotice: false,
      currentProcess: null,
      showDescriptionModal: false,
      localDescription: ''
    };
  },
  props: {
    sortDirections: {
      type: Object,
      required: false
    },
    sort: {
      type: Object,
      required: false,
      default: () => ({
        title: '',
        id: '',
        categoryTitle: '',
        state: '',
        employee_id: '',
      })
    },
    project: {
      type: Object,
      required: false
    },
    mode: {
      type: String,
      required: true
    }
  },
  computed: {
    normalizedSortDirections() {
      const defaultSortDirections = {
        lieferanschrift_land: '',
        title: '',
        anzahlAnlagen: '',
        kwel: '',
        preisProKw: '',
        projektGesamtkosten: ''
        // Add other fields as needed
      };

      // Return the sortDirections, merged with defaultSortDirections to ensure all keys exist
      return { ...defaultSortDirections, ...this.sortDirections };
    },
    ...mapGetters(["customerActive", "getPlugins", "getConfigs", "getEmployees"])
  },
};
</script>
