<template>
  <div class="productItem activeProduct">
    <div class="card">
      <div class="card-header ">
         <h6 class="inline"><i class="fa-duotone fa-square-info"></i> {{$t("costNumbers.details.newCostNumber")}}</h6>
        <button type="button" class="btn btn-close float-end ms-4"
                @click="$store.commit('closeConfigCostNumbers');$store.commit('closeModal', 'ConfigCostNumbers');">
        </button>
          <button type="button" class="btn btn-outline-primary btn-sm me-4 float-end ms-3" @click="updateConfig()"><i
            class="fa-duotone fa-floppy-disk"></i> {{$t("globals.save")}}
          </button>

          <button type="button" class="btn btn-outline-secondary btn-sm float-end " v-if="d.id" @click="deleteConfig()"><i
            class="fa-duotone fa-trash"></i> {{$t("globals.delete")}}
          </button>
      </div>



      <div class="card-body" v-if="d">
        <div class="row">
          <div class="col-lg-3 col-md-6 col-12">
            <div class="form-group">
              <label>{{$t("globals.designation")}}</label>
              <input class="form-control" type="text" v-model="d.cost_title">
            </div>
          </div>

          <div class="col-lg-3 col-md-6 col-12">
            <div class="form-group">
              <label>{{$t("globals.number")}}</label>
              <input class="form-control" type="number" v-model="d.cost_number"  step="1" min="0">
            </div>
          </div>

          <div class="col-lg-3 col-md-6 col-12">
            <div class="form-group">
              <label>{{$t("costNumbers.details.costType")}}</label>

              <Multiselect
                :options="[{'value': 'KOST-1', 'label': $t('costNumbers.details.costOne')}, {'value': 'KOST-2', 'label': $t('costNumbers.details.costTwo')}]"
                :multiple="false"
                :label="'label'"
                v-model="d.cost_type"
                :can-clear="false"
                :valueProp="'value'"
                :searchable="false"
              ></Multiselect>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 col-12">
            <div class="form-group" v-if="getConfigCostNumbers   && getEmployees">
              <label>{{$t("globals.employeeInCharge")}}</label>

              <Multiselect
                :options="getEmployees"
                :multiple="false"
                :label="'e_name'"
                v-model="d.cost_employee_id"
                :can-clear="false"
                :valueProp="'id'"
                :searchable="false"
              ></Multiselect>
            </div>
          </div>

          <div class="form-group">
            <div class="col-lg-6 col-12">
              <label>{{$t("costNumbers.details.descriptionIn")}}</label>
              <Editor2 v-model="d.cost_description_in"/>
            </div>
          </div>

          <div class="form-group">
            <div class="col-lg-6 col-12">
              <label>{{$t("costNumbers.details.descriptionOut")}}</label>
              <Editor2 v-model="d.cost_description_out"/>
            </div>

        </div>

        </div>
      </div>
    </div>
  </div>



</template>
<script>
import axios from "axios";
import Editor2 from "@/components/Editor2";
import {mapGetters} from "vuex";
import Headline from "@/routing/core/Config/Accounting/CostNumbers/Layouts/Headline.vue";
import $ from "jquery";
import Multiselect from '@vueform/multiselect'

export default {
  name: "CostNumbersDetails",
  components: {
    Headline,
    Editor2,
    Multiselect
  },
  computed: {
    ...mapGetters(["getEmployees", "getConfigCostNumbers", "getConfigs", "draftList"])
  },
  data() {
    return {
      d: {
        cost_title: '',
        cost_number: null,
        cost_type: null,
        cost_employee_id: null,
        cost_description_in: '',
        cost_description_out: '',
      },
      isNew: true,

    };
  },
  methods: {
    updateConfig() {
      let VueInner = this;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      if (VueInner.d.id !== undefined) {
        axios.put(this.$store.state.apiUrl + "config/costNumbers/" + VueInner.d.id, VueInner.d, {headers}).then(() => {

          // dispatch toastSuccess

          // Load Products
          this.$store.dispatch("loadConfig", {type:  "costNumbers", percent: 30, message: 'Bits werden gezüchtet'}).then(() => {
            VueInner.$store.dispatch('toastSuccess', 'Kostenstelle wurde erfolgreich gespeichert');
            VueInner.$store.commit("setConfigLoader", {loadPercent: 0});
            VueInner.$store.commit('closeConfigCostNumbers');
          });

        }).catch((r) => {
          this.$store.dispatch("handleApiResponse", r.response);
        });
      } else {
        axios.post(this.$store.state.apiUrl + "config/costNumbers/", VueInner.d, {headers}).then(() => {


          // dispatch toastSuccess
          this.$store.dispatch("loadConfig", {type: "costNumbers", percent: 30, message: 'Bits werden gezüchtet'}).then(() => {
            VueInner.$store.commit('closeModal', 'ConfigCostNumbers');
            VueInner.$store.commit("setConfigLoader", {loadPercent: 0});
            VueInner.$store.dispatch('toastSuccess', 'Kostenstelle wurde erfolgreich gespeichert');
            VueInner.$store.commit('closeConfigCostNumbers');
          });
          // Load Products

        }).catch((r) => {
          this.$store.dispatch("handleApiResponse", r.response);
        });
      }
    },
    deleteConfig() {
      let VueInner = this;
      this.$confirm('Soll die Kostenstelle wirklich gelöscht werden?', 'KostenStelle löschen', "question", {
        confirmButtonText: 'Ja',
        cancelButtonText: 'Nein',
        confirmButtonColor: '#6f42c1',
        type: 'warning'
      }).then(() => {
        const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
        axios.delete(this.$store.state.apiUrl + "config/costNumbers/" + VueInner.d.id, {headers}).then(() => {
          // close
          // dispatch toastSuccess
          this.$store.dispatch("loadConfig",{type:  "costNumbers", percent: 30, message: 'Bits werden gezüchtet'}).then(() => {
            VueInner.$store.dispatch('toastSuccess', 'Kostenstelle wurde erfolgreich gelöscht');
            VueInner.$store.commit("setConfigLoader", {loadPercent: 0});
            VueInner.$store.commit('closeConfigCostNumbers');

          });
          // Load Products
        }).catch((r) => {
          this.$store.dispatch("handleApiResponse", r.response);
        });
      }).catch((r) => {
        console.log(r);
      });
    },

    loadDraft(retry = true) {
      const draftId = Number(this.$route.params.id); // Convert route ID to number

      if (!draftId) {
        this.isNew = true; // No route ID, setting as a new draft
        return;
      }

      // Check if getConfigs and customerLead are available
      if (!this.getConfigs || !this.getConfigs.costNumbers) {
        if (retry) {
          // Retry once if state is not ready
          setTimeout(() => {
            this.loadDraft(false); // Retry without allowing further retries
          }, 300); // Adjust delay if necessary
        }
        return;
      }

      // Find the draft in customerLead (update this line for other config types)
      const draftFromStore = this.getConfigs.costNumbers.find(d => Number(d.id) === draftId);

      if (draftFromStore) {
        this.$store.commit("setActiveConfigCostNumbers", draftFromStore); // Commit active draft to the store
        this.d = draftFromStore; // Set the local draft
        this.isNew = false;
      } else {
        // Redirect if the draft is not found
        this.$router.push("/config/accounting/costnumbers");
      }
    },
    scrollToTop() {
      $("html, body").animate({scrollTop: 0});
    },
  },
  created() {
    this.loadDraft();
  },
  watch: {
    getEmployees: {
      handler(newValue) {
        if (newValue && this.d && Object.keys(this.d).length) {
          // Ensure Multiselect options are updated when employees are loaded
          this.loadDraft(false);
        }
      },
      immediate: true,
    },
  }
};
</script>

