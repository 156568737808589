<template>
  <div class="card-body draft-list">
      <p class="text-sm text-secondary" v-if="!getConfigs || getConfigs && getConfigs.customerTax && getConfigs.customerTax.length === 0">Es wurden keine Vorlagen
        gefunden.</p>
    <Sort @sort="resortOrder($event)" />
      <div  class="draft-item" v-for="draft in getConfigs.customerTax"
           :key="draft.id">
        <Headline :draft="draft"/>
      </div>
    <router-link to="/config/accounting" class="mt-5 mb-5" style="display: block;font-size: 14px;color: #b21f28;"><i class="fa-duotone fa-chevron-left"></i> {{$t('nav.back')}}</router-link>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import Details from "@/routing/core/Config/Accounting/CustomerTaxes/Details.vue";
import Headline from "@/routing/core/Config/Accounting/CustomerTaxes/Layouts/Headline.vue";
import Pagination from "@/filter/components/pagination.vue";
import Sort from "@/routing/core/Config/Accounting/CustomerTaxes/Layouts/Sort.vue";
import $ from "jquery";

export default {
  name: "DraftSearch",
  computed: {
    ...mapGetters(["getEmployees", "draftList", "getConfigs", "draftActive"])
  },

  components: {
    Sort,
    Pagination,
    Headline,
    Details,
  },
  methods: {

    getCustomerTaxes(){
      this.$store.dispatch("customerTaxesFilter");
    },
    resortOrder(payload) {
      if(payload.direction) {
        this.$store.state.filters.customerTaxes.ordering.value = payload.type;
        this.$store.state.filters.customerTaxes.direction.value = payload.direction;
      } else {
        this.$store.state.filters.customerTaxes.ordering.value = payload.type
      }
      this.getCustomerTaxes();
    },
    scrollToTop() {
      $("html, body").animate({scrollTop: 0});
    },
  },
  created() {
  },
  watch: {
    $route(to, from) {
    },
    draftActive() {
    }
  }

};
</script>
