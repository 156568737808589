<template>

  <div class="document-list card-body"  v-if="documentActive">
    <div class="document-item activeDocument">
      <Headline :document="documentActive"/>
      <div class="documentContent w-100">
      <div class="card w-100">
        <Edit  :document="documentActive" @update-doc="updateDocument" :modal="false"/></div>
      </div>
    </div>
  </div>
</template>
<script>
import Edit from "@/routing/core/Documents/Edit";
import Documents from "@/routing/core/Documents/Documents";
import Headline from "@/routing/core/Documents/Layouts/Headline";
import {mapGetters} from "vuex";
import Editor from "@/components/Editor2";
import Actions from "@/routing/core/Documents/Actions";
import axios from "axios";
import $ from "jquery";
export default {
  name: "DocumentDetails",
  components: {
    Headline,
    Documents,
    Editor,
    Actions,
    Edit,
  },
  computed: {
    ...mapGetters(["getPlugins", "getEmployees", "documentActive"])
  },
  methods: {
    updateDocument(doc){
      console.log("update Doc triggered with doc-id: ", doc.id);
      this.$store.dispatch("setActiveDocument", doc.id);
    },
    loadDocument() {
      if (this.$route.params.id) {
          this.$store.dispatch("setActiveDocument", this.$route.params.id);
            this.scrollToTop();
        this.isNew = false;
      } else {
        this.isNew = true;
      }
    },
    scrollToTop() {
      $("html, body").animate({scrollTop: 0});
    },
  },
  data() {
    return {

      d: {},
      isNew: true
    }
  },
  created() {
    this.loadDocument();

  },

};
</script>
