<template>
    <ul class="navbar-nav">

      <li class="filter-item" v-for="filter in $store.state.filters.projects">
        <template v-if="filter.name ==='customer'">
          <template v-if="$store.state.filters.projects.customer.value ===''">
            <customersearch class="small" v-model="$store.state.filters.projects.customer.value" :onlyid="true"
                            @changedCustomer="updateValue()"/>
          </template>
          <template v-else>
            <button class="btn btn-outline-primary btn-sm w-100" style="margin-bottom: 12px;"
                    @click="$store.state.lists.projects.offset = 0;$store.state.filters.projects.customer.value = '';$store.state.filters.projects.customer.value = ''; updateValue();">
              {{ $t('reset.customerfilter') }}
            </button>
          </template>
        </template>
        <template v-else>
        <component :is="'sketchFilter'+filter.type" :type="'projects'" :filter="filter" v-if="filter"/></template>
      </li>
    </ul>
</template>
<script>
import sketchFilterdatetime from "@/filter/components/datetime.vue";
import sketchFilterinput from "@/filter/components/textinput.vue";
import sketchFilterlist from "@/filter/components/list.vue";
import sketchFiltermultilist from "@/filter/components/multilist.vue";
import axios from "axios";
import sketchFilterfromtill from "@/filter/components/fromtill.vue";
import {mapGetters} from "vuex";
import customersearch from "@/filter/components/customersearch.vue";

export default {
  name: "FiltersProjects",
  data() {
    return {
      projectform: [],
    };
  },
  components: {
    customersearch,
    sketchFilterlist, sketchFiltermultilist, sketchFilterinput, sketchFilterdatetime, sketchFilterfromtill
  },

  methods:{
    getPluginData() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "plugins/customerfields", {headers}).then((r) => {
        this.projectform = r.data.result.data;
        this.prepareCustomFilter();
      });
    },
    updateValue() {
      this.$store.dispatch('projectsFilter');
    },
    prepareCustomFilter() {
      this.projectform.forEach((formItem) => {
        formItem.fields.forEach((fieldItem) => {
          this.$store.commit('addFilter', {
            group: 'projects',
            title: fieldItem.title,
            type: this.prepareType(fieldItem.type), // Assuming prepareType is a method handling the type
            name: fieldItem.name,
            source: this.prepareSource(fieldItem.fieldparams.options), // Assuming prepareSource handles the options
            value: ""
          });
        });
      });
    },
    prepareSource(options) {
console.log(options);
      // Convert object properties to an array
      if(!options) return [];
      let source = Object.keys(options).map(key => {
        return {
          label: options[key].name,
          id: options[key].value
        };
      });
      return source;
    },
    prepareType(type) {
      switch (type) {
        case "text":
        case "integer":
          return "input";
        case "select":
        case "radio":
          return "list";
        case "multiselect":
          return "multilist";
        case "calendar":
          return "datetime";
      }
    }
  },
  created() {

  },
  watch: {
    '$store.state.filters.projects.customer.value'(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.updateValue();
      }
    }
  }
};
</script>
