<template>
  <div class="card">
    <div class="card-body">
      <h1>Trainer-Lizenz Verlängerungen</h1>
      <p style="text-align: center">Die Datenbank zeigt Mitglieder mit Vertragslaufzeit bis {{ today }}, die 20
        Lerneinheiten gesammelt haben.</p>
      <div style="text-align: center" class="searchCustomer">
        <div class="row">
          <div class="columns xlarge-4 large-12 medium-12 small-12">
            <input style="max-width: 80%; font-size: 14px;" @keypress.enter="searchField()" v-model="searchPhrase"
                   type="text" placeholder="Mitgliedsnummer oder Name durchsuchen">
            <button style="margin-left: 12px; max-width: 10%" class="btn"
                    @click="loadCustomers(); searchPhrase = ''; feedbackSearch = false">X
            </button>
          </div>
          <div class="columns xlarge-4 large-4 medium-12 small-12">
            <button class="btn" @click="filteredEndDate = null; filterMembers(filterState);"
                    :class="({active: (filteredEndDate === null)})">Alle Jahre
            </button>
            <button class="btn" @click="filteredEndDate = lastYear; filterMembers(filterState);"
                    :class="({active: (filteredEndDate === lastYear)})">Nur Vorjahre
            </button>
          </div>
          <div class="columns xlarge-4 large-4 medium-12 small-12">
            <button class="btn" @click="filterMembers('none')" :class="({active: (filterState==='none')})">Alle</button>
            <button class="btn" @click="filterMembers('pending')" :class="({active: (filterState==='pending')})">
              Verlängerung abzuschließen
            </button>
            <button class="btn" @click="filterMembers('inaktiv')" :class="({active: (filterState==='inaktiv')})">
              Bearbeitung
            </button>
          </div>
        </div>
        <div class="feedbackSearch">
          <div class="text" v-if="feedbackSearch" v-html="feedbackSearch"></div>
        </div>
      </div>
      <div class="loader" :class="{loading:preload}">
        <div class="init" :class="{loading:preload}"></div>
        <p class="">{{ filteredMembers.length }} Ergebnisse</p>
        <table class="renews">
          <thead>
          <tr>
            <th style="cursor: pointer" @click="sortAsc = !sortAsc; sortRegistrationsCustomerNo()">Mitgliedsnummer <span
              v-if="activeSorter === 'customerNumber'"><template v-if="sortAsc">&uarr;</template><template
              v-else>&darr;</template></span></th>
            <th style="cursor: pointer" @click="sortAsc = !sortAsc; sortRegistrationsName()">Name <span
              v-if="activeSorter === 'name'"><template v-if="sortAsc">&uarr;</template><template
              v-else>&darr;</template></span></th>
            <th class="center" style="font-weight: normal">Geburtsdatum</th>
            <th style="font-weight: normal">E-Mail</th>
            <th style="cursor: pointer" @click="sortAsc = !sortAsc; sortRegistrationsDate()">Lizenz <span
              v-if="activeSorter === 'date'"><template v-if="sortAsc">&uarr;</template><template
              v-else>&darr;</template></span></th>
            <th class="center" style="font-weight: normal">Lerneinheiten</th>
            <th style="font-weight: normal">Aktion</th>
          </tr>
          </thead>
          <tr class="renew" :class="m.autoRepeat" v-if="m.customer" v-for="m in filteredMembers">
            <td>{{ m.customer.c_customer_id }}</td>
            <td>{{ m.customer.c_lastname }} {{ m.customer.c_firstname }}
              <p v-if="m.autoRepeat === 'active'" style="font-size: 14px">
                {{ m.customer.c_street }}<br>{{ m.customer.c_zipcode }}
                {{ m.customer.c_city }}<br>{{ m.customer.countryName }}</p>
            </td>
            <td class="center" style="font-size: 14px">
              <template v-if="m.autoRepeat === 'active'">{{ m.customer.c_birthday }}</template>
            </td>
            <td style="font-size: 14px">
              <template v-if="m.autoRepeat === 'active'">{{ m.customer.c_email }}</template>
            </td>
            <td>{{ m.lizenznummerDfb }}<p v-if="m.autoRepeat === 'active'" style="font-size: 14px">
              {{ m.license | license }}</p>
              <p v-if="m.autoRepeat === 'active'" style="font-size: 12px">{{ m.date2 | date }} - {{ m.date | date }}
                <template v-if="m.finishedMail !== '1999-01-01'"><br>LE erreicht am {{ m.finishedMail | date }}
                </template>
              </p>
            </td>
            <td class="center">
              <template v-if="m.autoRepeat === 'active'">
                <template v-if="!m.le">0</template>
                <template v-else>{{ m.le }} / {{ m.leMax }}</template>
                <span class="icon-eye" data-title="Details" style="cursor:pointer;"
                      @click="renderLE(m.customer.id)"></span></template>
            </td>
            <td>
              <template v-if="m.autoRepeat === 'inactive'">
                Das Mitglied hat der Verlängerung widersprochen!
              </template>
              <template v-else-if="m.contractInfo && m.contractInfo === 'no'">Keine aktive Mitgliedschaft beim BDFL
              </template>
              <template v-else>
                <template v-if="!m.incomplete">
                  <button v-if="!m.contract || m.contract === 'inaktiv'" class="btn" @click="edit(m.customer.id)">
                    Bearbeitung starten
                  </button>
                  <button v-else-if="m.contract === 'pending'" class="btn" @click="renew(m.customer.id)">Verlängerung
                    abschließen
                  </button>
                </template>
                <template v-else>
                  Lerneinheiten nicht erreicht <br><br>
                  <button v-if="!m.contract || m.contract === 'inaktiv'" class="btn" @click="edit(m.customer.id)">
                    Bearbeitung trotzdem starten
                  </button>
                  <button v-else-if="m.contract === 'pending'" class="btn" @click="renew(m.customer.id, 'ignore')">
                    Verlängerung trotzdem abschließen
                  </button>
                </template>
              </template>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import VuePdfEmbed from "vue-pdf-embed";

export default {
  name: "LicenseRenewal",
  components: {
    VuePdfEmbed
  },
  emits: ['sort', 'refreshAfterSave'],
  data() {
    const now = new Date();
    // Calculate the last year and last day of that year
    const lastYearDate = new Date(now.getFullYear() - 1, 11, 31);
    const lastYear = lastYearDate.toISOString().split('T')[0]; // "YYYY-MM-DD" format
    // Today's date in "YYYY-MM-DD" format
    const today = now.toISOString().split('T')[0];
    return {
      apiURL: "https://api.bdfl.de/",
      accessToken: "Bearer ScKsgP2X5CkNZsNqHXVwthdKtmpLYh2XV36LFfsuYLq4Km8d2kTHqC26qfSxyRtQ88kNsN5btLwxJ3PKUS7vGmryGK6knRXpYckgLspnQTWBqgcdV8kHAeJVtSQr8ERcsBusstfLr53wwxHtbJ5WYKhyaFzaSDzxyvXgxqzXXwTmVmES9adtTnw2stxZhPm3sBusstfLr53wwxHtbJ5WYKhyaFzaSDzxyvXgxqzXXwTmVmES9adtTnw2stxZhPm3wthdKtmpLYh2XV36LFfsuYLq4Km8d2kTHqC26qfSxyRtQ88kNsN5btLwxJ3PKUS7vGmryGK6knRXpYckgLspnQTWBqgcdV8kHAeJVtSQr8ERcsBusstfLr53wwxHtbJ5WYKhyaFzaSDzxyvXgxqzXXwTmVmES9adtTnw2stxZhPm3sBusstfLr53wwxHtbJ5WYKhyaFzaSDzxyvXgxqzXXwTmVmESh2XV36LFfsuYLq4Km8d2kTH",
      dates: [],
      searchPhrase: '',
      useMembers: [],
      filteredMembers: [],
      searchMembers: [],
      feedbackSearch: '',
      preload: false,
      filterState: 'none',
      activeSorter: 'date',
      sortAsc: true,
      lastYear: lastYear,
      filteredEndDate: null,
      today: today,
    }
  },
  filters: {
    date: function (value, format) {
      if (!value) {
        return '';
      }
      var dater = new Date(value);
      var options = format || {year: 'numeric', month: '2-digit', day: '2-digit'};
      return dater.toLocaleDateString('de-DE', options)
    },
    license: function (value) {
      if (value === 'a') {
        return "A Lizenz";
      } else if (value === 'pro') {
        return "Pro Lizenz";
      } else if (value === 'aplus') {
        return "A+ Lizenz";
      } else {
        return "unbekannte Lizenz: " + value;
      }
    }
  },
  methods: {
    sortRegistrationsCustomerNo() {
      if (this.sortAsc) {
        this.filteredMembers.sort((a, b) => {
          return a.customer.c_customer_id - b.customer.c_customer_id
        })
      } else {
        this.filteredMembers.sort((a, b) => {
          return b.customer.c_customer_id - a.customer.c_customer_id
        })
      }
      this.activeSorter = 'customerNumber';
    },
    sortRegistrationsDate() {
      if (this.sortAsc) {
        this.filteredMembers.sort((a, b) => {
          return new Date(a.finishedMail) - new Date(b.finishedMail)
        })
      } else {
        this.filteredMembers.sort((a, b) => {
          return new Date(b.finishedMail) - new Date(a.finishedMail)
        })
      }
      this.activeSorter = 'date';
    },
    sortRegistrationsName() {
      // sort filteredMembers by name
      if (this.sortAsc) {
        this.filteredMembers.sort((a, b) => {
          if (a.customer.c_lastname < b.customer.c_lastname) {
            return -1;
          }
          if (a.customer.c_lastname > b.customer.c_lastname) {
            return 1;
          }
          return 0;
        })
      } else {
        this.filteredMembers.sort((a, b) => {
          if (a.customer.c_lastname > b.customer.c_lastname) {
            return -1;
          }
          if (a.customer.c_lastname < b.customer.c_lastname) {
            return 1;
          }
          return 0;
        })
      }
      this.activeSorter = 'name';
    },
    renderLE(cId) {
      this.preload = true;
      var payload = {
        customerId: cId,
        type: "renderLE",
      }
      axios.post(this.apiURL + "datescheckin", payload, {
        headers: {}
      }).then(function (response) {
        var shortResponse = response.data;
        jQuery.alert(response.data);
        this.preload = false;
      }).catch(function (error) {
        console.log(error)
        this.preload = false;
      });
    },
    inArray(payload) {
      var haystack = payload.haystack;
      var needle = payload.needle;
      if (haystack === undefined) {
        return false;
      }
      var length = haystack.length;
      for (var i = 0; i < length; i++) {
        if (haystack[i] === needle) return true;
      }
      return false;
    },
    filterDate(date) {
      let d1 = new Date();
      let d2 = new Date(date);
      console.log(date);
      console.log(d1.getTime());
      console.log(d2.getTime());
      let toLate = d1.getTime() < d2.getTime();
      if (toLate) {
        return "red";
      }
      return "green";
    },
    filterMembers($filter) {
      if ($filter === undefined) {
        $filter = this.filterState;
      } else {
        this.filterState = $filter;
      }
      if ($filter === 'none') {
        this.filteredMembers = this.searchMembers;
      } else {
        let i;
        let current;
        let filtered = [];
        for (i in this.searchMembers) {
          current = this.searchMembers[i];
          if ($filter === 'pending') {
            if (current.contract === 'pending') {
              filtered.push(current);
            }
          } else if ($filter !== 'pending') {
            if (current.contract !== 'pending') {
              filtered.push(current);
            }
          }
        }
        this.filteredMembers = filtered;
      }
      if (this.filteredEndDate) {
        let i;
        let current;
        let filtered = [];
        for (i in this.filteredMembers) {
          current = this.filteredMembers[i];
          if (current.date === this.filteredEndDate || current.date < this.filteredEndDate) {
            filtered.push(current);
          }
        }
        this.filteredMembers = filtered;
      }
    },
    searchField() {
      if (this.searchPhrase.length < 3) {
        this.feedbackSearch = 'Bitte mindestens 3 Zeichen eingeben....';
      } else {
        this.preload = true;
        var payload = {
          phrase: this.searchPhrase,
          type: "searchCustomers",
        }
        axios.post(this.apiURL + "datescheckin", payload, {
          headers: {}
        }).then(function (response) {
          var shortResponse = response.data;
          if (shortResponse.success) {
            var customers = shortResponse.customers;
            if (customers.length > 0) {
              this.filteredMembers = customers;
              this.searchMembers = customers;
            } else {
              this.feedbackSearch = 'Keine Ergebnisse';
            }
          } else {
            this.feedback = shortResponse.notice;
          }
          this.preload = false;
        }).catch(function (error) {
          console.log(error)
          this.preload = false;
        });
        var Vueinner = this;
        setTimeout(function () {
          Vueinner.feedback.message = "";
        }, 10000);
      }
    },
    edit($customerId) {
      this.license($customerId, "pending");
    },
    renew($customerId, $leTypeHandling) {
      this.license($customerId, "inaktiv", $leTypeHandling);
    }
    ,
    license($customerId, $state, $leTypeHandling) {
      if ($leTypeHandling === undefined) {
        $leTypeHandling = 'normal';
      }
      this.preload = true;
      var payload = {
        customerId: $customerId,
        type: "renewLicense",
        state: $state,
        leTypeHandling: $leTypeHandling,
      }
      const headers = {"authorization": this.accessToken};
      axios.post(this.apiURL + "datescheckin", payload, {headers}).then(function (response) {
        var shortResponse = response.data;
        if (shortResponse.success) {
          if (shortResponse.notice && shortResponse.notice.message) {
            this.feedbackSearch = shortResponse.notice.message;
          }
          if (this.searchPhrase) {
            this.searchField();
          } else {
            this.loadCustomers();
          }
        } else {
          this.feedbackSearch = shortResponse.notice.message;
          this.preload = false;
        }
      }).catch(function (error) {
        console.log(error)
        this.preload = false;
      });
      var Vueinner = this;
      setTimeout(function () {
        Vueinner.feedbackSearch = "";
      }, 10000);
    },
    loadCustomers() {
      this.preload = true;

      // bearer token

      const headers = {"Authorization": this.accessToken};
      axios.get(this.apiURL + "trainer/licenseRenewal/customers", {headers}).then(function (response) {
        var shortResponse = response.data;
        if (shortResponse.success) {
          var customers = shortResponse.customers;
          if (customers.length > 0) {
            this.filteredMembers = customers;
            this.searchMembers = customers;
            this.feedbackSearch = '';
          } else {
            this.feedbackSearch = 'Keine Ergebnisse';
          }
        } else {
          this.feedback = shortResponse.notice;
        }
        this.preload = false;
      }).catch(function (error) {
        console.log(error)
        this.preload = false;
      });
    }
  },
  created() {
    this.loadCustomers();
  },
  computed: {},
  mounted() {
  },
  watch: {
    trackType() {
      this.filterRegistrations();
    },
    activeDate() {
      this.filterRegistrations();
    }
  }
}
</script>
