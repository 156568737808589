<template>
  <div class="card-body draft-list project-list alllistView">
    <p class="text-sm text-secondary" v-if="!projectList || projectList.data.length === 0">Es wurden keine Projekte
      gefunden.</p>
    <Sort @sort="resortOrder($event)"/>
    <div class="draft-item" v-for="project in projectList.data"
         :key="project.id">
      <Holzkraft :project="project" mode="listHeadline"
                 v-if="getPlugins && getPlugins.infos && getPlugins.infos.holzkraft"/>
      <template v-else>
        <div class="draftLineWrap projectLine"
             :class="(project.prepared && project.prepared.typeString) ? project.prepared.typeString : ''"
            >
          <div class="lineitem first l-checkbox">
            <div class="checkbox">
              <input type="checkbox"
                     :id="'id_'+project.id"
                     :value="project.id"
                     v-if="projectList && projectList.selected"
                     v-model="projectList.selected[project.id]"/>
              <label :for="'id_'+project.id"></label>
            </div>
          </div>
          <div class="lineitem l-title" @click="$store.commit('closeProject');setDraft(project)">
            {{ project.title }}
          </div>
          <div class="lineitem l-state">
<span v-if="project._projectStateTitle" :tooltip="project._projectStateTitle" class=" w-100">
            <span :style="'color: '+ project._projectStateColor +';'"><i v-if="project._projectStateIcon"
                                                                              class="fa-duotone"
                                                                              :class="project._projectStateIcon"></i></span></span>
          </div>
          <div class="lineitem l-customer">
          <span v-if="project.c_company || (project.c_firstname && project.c_lastname)">
            <template v-if="project.c_company">{{ project.c_company }}<br></template>
            {{ project.c_firstname }} {{ project.c_lastname }}
          </span>
          </div>
          <div class="lineitem l-issue_count open">
            <span> {{ project._openIssuesCount }} / {{ project._issuesCount }}</span>
          </div>
          <div class="lineitem l-tags"
               v-if="getConfigs && getConfigs.params_values && getConfigs.params_values.use_tags_in_listing === '1'">
            <template v-if="project && project.tagsFormatted && project.tagsFormatted.length > 0">
        <span v-for="tag in project.tagsFormatted" :key="tag.id" class="badge badge-primary badge-xs me-1"
              :style="'background-color:'+tag.color">
          <template v-if="tag.icon"><i class="fa-duotone" :class="'fa-'+tag.icon"></i></template>
          {{ tag.name }}</span>
            </template>
          </div>
          <div class="lineitem l-actions">
            <span class="iconAction btn btn-sm btn-outline-secondary" v-if="project && project.id"
                  @click="getNotices(project.id);addProjectNotice = true;notice.project_id = project.id"
                  tooltip="Notiz erstellen">
      <i class="fa-note-sticky fa-duotone"></i><span v-if="project._countNotices > 0"> <span class="incNumber">{{
                project._countNotices
              }}</span></span>
            </span>
          </div>
          <div class="lineitem l-avatar ml-auto">
            <img style="max-width: 40px" :src="$filters.employeeIcon(getEmployees, project.employee_id, 'id')"
                 class="sketchAvatar" v-if="project.employee_id">
          </div>
          <div class="lineitem last ms-2">
            <template v-if="projectActive && projectActive.id === project.id">
              <i class="fa-duotone fa-x fa-swap-opacity" @click="$store.commit('closeProject');"></i>
            </template>
            <template v-else><i class="fa-duotone fa-chevrons-up" @click="setDraft(project)"></i></template>
          </div>
        </div>
      </template>
    </div>
    <pagination list="projects" listName="projectList" filterName="projectsFilter"/>
    <modal v-if="addProjectNotice">
      <div class="modal fade show purpleView" tabindex="-1"
           style="display: block;">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5  class="modal-title">
                <template v-if="notice && notice.id">
                  {{ $t("globals.editNote") }}
                </template>
                <template v-else>
                  {{ $t("globals.newNote") }}
                </template>
              </h5>

              <template v-if="notice && notice.id">
                <button type="button" style="margin-left: auto;" class="btn btn-outline-primary purple"
                        @click="editNotice()"><i
                  class="fa-duotone fa-floppy-disk"></i> {{ $t("globals.save") }}
                </button>
              </template>
              <template v-else>
              <button type="button" style="margin-left: auto;" class="btn btn-outline-primary purple"
                      @click="postNotice()"><i
                class="fa-duotone fa-floppy-disk"></i> {{ $t("globals.save") }}
              </button></template>
              <button type="button" class="btn-close ms-3"
                      @click="addProjectNotice = false; notice.project_id = null;"></button>
            </div>
            <div class="modal-body">
              <div class="form-group">
                <label>{{ $t("globals.note") }}</label>
                <Editor2 v-model="notice.notice_message"/>
              </div>
              <template v-if="notices && notices.length > 0">
                <div class="noticesContainer" >
                  <template v-for="n in notices">
                    <div class="noticeItem" v-if="n.notice_type ==='project' && Number(n.affect_id) === Number(notice.project_id)">
                      <div class="noticeHeader mb-2">
                        <img :src="$filters.employeeIcon(getEmployees, n.notice_author, 'u_id')"
                             class="sketchAvatar me-2">
                        <span class="noticeDate"><small>{{ $filters.formatDateTime(n.notice_date) }}</small></span>
                        <button v-if="getUser && getUser.id && (getUser.id === n.notice_author)" type="button" @click="deleteNotice(n.id)" class="btn btn-outline-danger btn-sm float-end"><i class="fa-duotone fa-trash"></i></button>
                        <button v-if="getUser && getUser.id && (getUser.id === n.notice_author)" type="button" @click="setEditNotice(n)" class="btn btn-outline-primary btn-sm float-end me-1"><i class="fa-duotone fa-edit"></i></button>
                      </div>
                      <div class="noticeMessage" v-html="n.notice_message"></div>
                      <hr>
                    </div>
                  </template>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import axios from "axios";
import Edit from "@/routing/core/Projects/Edit.vue";
import Details from "@/routing/core/Projects/Details.vue";
import Headline from "@/routing/core/Projects/Layouts/Headline.vue";
import Sort from "@/routing/core/Projects/Layouts/Sort.vue";
import Pagination from "@/filter/components/pagination.vue";
import Holzkraft from "@/routing/plugins/holzkraft/subs/projects.vue";
import Editor2 from "@/components/Editor2.vue";

export default {
  name: "List",
  emits: ['refresh-project-list', 'new-project', 'project-active'],
  components: {Editor2, Holzkraft, Pagination, Sort, Headline, Details, Edit},
  props: {},
  data() {
    return {
      newProjectItem: {},
      accessData: [],
      projects: [],
      notice: {
        project_id: null,
        notice_message: ''
      },
      notices: [],
      addProjectNotice: false,
    }
  },
  computed: {
    ...mapGetters(["getEmployees", "modalsList", "projectList", "projectActive", "getPlugins", "getConfigs", "getUser"])
  },
  methods: {
    setDraft(project) {
      this.$store.commit('closeProject');
      // next tick
      this.$nextTick(() => {
        this.$store.dispatch("setActiveProject", project);
        this.$router.push({name: 'ProjectDetails', params: {id: project.id}})
        this.scrollToTop();
      });
    },
    resortOrder(payload) {
      if (payload.direction) {
        this.$store.state.filters.projects.ordering.value = payload.type;
        this.$store.state.filters.projects.direction.value = payload.direction;
      } else {
        this.$store.state.filters.projects.ordering.value = payload.type
      }
      this.$store.dispatch("projectsFilter");
    },
    getNotices(project_id) {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.getters.getApiUrl + "project/" + project_id + "/notices", {headers}).then((r) => {
        this.notices = r.data.result.data;
      }).catch((e) => {
        this.toast.error(e.response.data.result.message);
      });
    },
    postNotice() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      let postContent = {message: this.notice.notice_message}
      axios.post(this.$store.state.apiUrl + "project/" + this.notice.project_id + "/notice", postContent, {headers}).then((r) => {
        this.addDocNotice = false;
        this.notice.notice_message = '';
        this.notice.project_id = null;
        // close modal
        this.addProjectNotice = false;
        this.$store.dispatch("toastSuccess", 'Notiz wurde erfolgreich gespeichert');

      }).catch((e) => {
        this.$store.dispatch("handleApiResponse", e.response);
      });
    },
    setEditNotice(notice){
      this.notice = notice;
    },
    editNotice() {
      let Vueinner = this;
      this.loading = true;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      let editedNotice = {
        data: {
          message: this.notice.notice_message
        }
      }
      axios.put(this.$store.state.apiUrl + "notice/" + Vueinner.notice.id, editedNotice, {headers}).then((r) => {
        Vueinner.notices = r.data.result.data;
        Vueinner.loading = false;
        this.notice = null;
        this.$store.dispatch('toastSuccess', 'Notiz erfolgreich bearbeitet.');
      });
    },
    deleteNotice(id) {
      let Vueinner = this;
      this.loading = true;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.delete(this.$store.state.apiUrl + "notice/" + id, {headers}).then((r) => {
        Vueinner.notices = r.data.result.data;
        Vueinner.loading = false;
        this.$store.dispatch('toastSuccess', 'Notiz erfolgreich gelöscht.');
      });
    },

    scrollToTop() {
      $("html, body").animate({scrollTop: 0});
    },
  },
  created() {
    this.$store.dispatch("projectsFilter");
  },
  watch: {
    $route(to, from) {
      this.$store.dispatch("projectsFilter");
    }
  }
}
</script>

