<template>
  <div class="card">
    <div class="card-header">
      <h6>{{ project.title }}
        <button type="button" class="btn-close float-end" aria-label="Close" @click="closeProject"></button>
      </h6>
    </div>
    <div class="card-body pt-0">
      <ul class="tabNav">
        <li class="details" :class="(activeView === 'details') ? 'active' : ''" @click="activeView = 'details'"><i
          class="fa-duotone fa-info-circle"></i><br><small>Details</small></li>
        <li class="tasks" :class="(activeView === 'tasks') ? 'active' : ''" @click="activeView = 'tasks'" v-if="getUser && getUser.acl && !getUser.acl.menu || getUser.acl.menu.issues === 'yes'"><i
          class="fa-clipboard-list-check fa-duotone" title="Aufgaben" ></i><br><small>Aufgaben</small></li>
        <li class="notes" :class="(activeView === 'notes') ? 'active' : ''" @click="activeView = 'notes'" ><span style="position: relative;"><i
          class="fa-duotone fa-sticky-note"></i><span class="incNumber" style="transform: translateX(10px);" v-if="countNotices > 0">{{countNotices}}</span><br></span><small>Notizen </small></li>
      </ul>
      <template v-if="activeView === 'details'">
        <Holzkraft :project="project" mode="show" v-if="getPlugins && getPlugins.infos && getPlugins.infos.holzkraft"/>
        <p class="mt-3">{{ project.c_firstname }} {{ project.c_lastname }}
          <template v-if="project.c_company">- {{ project.c_company }}</template>
        </p>
        <div class="projectDesc" v-if="project.description">
          <label class="color">Beschreibung</label>
          <div v-html="project.description"></div>
        </div>
        <Edit :new-project-item="project" :modal="false" @refresh-project-list="closeProject()" :key="project.id"
              ref="editComponent"/>
        <div class="infoBlock"><i
          class="fa-duotone fa-calendar-circle-plus"></i> Erstellt: {{ $filters.formatDate(project.crm_created_date) }}
          |
          <i
            class="fa-duotone fa-calendar-circle-user"></i> Bearbeitet:
          {{ $filters.formatDate(project.crm_modified_date) }}
        </div>
      </template>
      <template v-if="activeView === 'tasks'">
        <div class="allTaskWrap appView tasks">
          <div class="task-list">
            <p class="text-sm text-secondary mt-3" v-if="!taskList.data || !taskList.data.length">
              {{ $t('tasks.list.tasksNotFound') }} </p>
            <div class="taskWrap" v-for="task in taskList.data"
                 :key="task.id">
              <Theadline :task="task"/>
            </div>
          </div>
        </div>
      </template>
      <template v-if="activeView === 'notes'">
        <div class="form-group" style="margin-top: 24px;text-align: right;" v-if="!showAddNotice">
          <button class="btn btn-outline-primary  " type="button" @click="showAddNotice = true"><i
            class="fa-duotone fa-plus"></i> Neue Notiz
          </button>
        </div>
        <div class="form-group" v-if="showAddNotice">
          <label>{{ $t("globals.note") }}</label>
          <Editor2 v-model="notice.notice_message"/>
          <template v-if="notice.id">
            <button type="button" class="btn btn-outline-primary me-2" @click="putNotice()"><i
              class="fa-duotone fa-floppy-disk"></i> {{ $t("globals.save") }}
            </button>
          </template>
          <template v-else>
            <button type="button" style="margin-left: auto;" class="btn btn-outline-primary me-2" @click="postNotice()">
              <i
                class="fa-duotone fa-floppy-disk"></i> {{ $t("globals.save") }}
            </button>
          </template>
          <button type="button" class="btn btn-outline-secondary" @click="showAddNotice = false"><i
            class="fa-duotone fa-x"></i> Abbrechen
          </button>
        </div>
        <template v-if="notices && notices.length > 0">
          <div class="noticesContainer">
            <template v-for="n in notices">
              <div class="noticeItem">
                <div class="noticeHeader mb-2">
                  <img :src="$filters.employeeIcon(getEmployees, n.notice_author, 'u_id')" class="sketchAvatar me-2">
                  <span class="noticeDate"><small>{{ $filters.formatDateTime(n.notice_date) }}</small></span>
                  <button v-if="getUser && getUser.id && (getUser.id === n.notice_author)" type="button"
                          @click="deleteNotice(n.id)" class="btn btn-outline-danger btn-sm float-end"><i
                    class="fa-duotone fa-trash"></i></button>
                  <button v-if="getUser && getUser.id && (getUser.id === n.notice_author)" type="button"
                          @click="setEditNotice(n)" class="btn btn-outline-primary btn-sm float-end me-1"><i
                    class="fa-duotone fa-edit"></i></button>
                </div>
                <div class="noticeMessage" v-html="n.notice_message"></div>
                <hr>
              </div>
            </template>
          </div>
        </template>
      </template>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import Holzkraft from "@/routing/plugins/holzkraft/subs/projects";
import Edit from "@/routing/core/Projects/Edit";
import Theadline from "@/routing/core/Tasks/Layouts/Headline";
import axios from "axios";
import Editor2 from "@/components/Editor2.vue";

export default {
  name: "Details",
  emits: ['refresh-project-list'],
  data() {
    return {
      activeView: 'details',
      project: {
        title: '',
        description: '',
        c_firstname: '',
        c_lastname: '',
        c_company: '',
        crm_created_date: '',
        crm_modified_date: '',
      },
      taskList: {
        data: []
      },
      notice: {
        document_id: null,
        notice_message: ''
      },
      notices: [],
      showAddNotice: false
    }
  },
  components: {
    Editor2,
    Holzkraft, Edit, Theadline
  },
  computed: {
    ...mapGetters(["projectActive", "getEmployees", "modalsList", "getPlugins", "projectList", "getUser"]),
    countNotices() {
      return this.notices.length;
    }
  },
  methods: {
    closeProject() {
      this.$store.commit('closeProject');
      this.$router.push({name: 'Projects'});
    },
    getTasks() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      const params = {
        offset: 0,
        limit: 0,
        filter_state: 'progress',
        filter_project: this.project.id,
        filter_customer: this.project.crm_customer_id,
      };
      axios.get(this.$store.state.apiUrl + "issue/search", {params, headers})
        .then((response) => {
          if (response.data && response.data.result && response.data.result.data) {
            this.taskList.data = response.data.result.data; // Correctly set the task list
          }
        })
        .catch((e) => {
          this.$store.dispatch('handleApiResponse', e.response);
        });
    },
    getNotices() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.getters.getApiUrl + "project/" + this.project.id + "/notices", {headers}).then((r) => {
        this.notices = r.data.result.data;
      }).catch((e) => {
        this.$store.dispatch('handleApiResponse', e.response);
      });
    },
    postNotice() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      let postContent = {message: this.notice.notice_message}
      axios.post(this.$store.state.apiUrl + "project/" + this.project.id + "/notice/", postContent, {headers}).then((r) => {
        this.showAddNotice = false;
        this.notice.notice_message = '';
        this.notice.document_id = null;
        this.$store.dispatch('toastSuccess', 'Notiz wurde erfolgreich gespeichert');
        this.getNotices();
      }).catch((e) => {
        this.$store.dispatch('handleApiResponse', e.response);
      });
    },
    putNotice() {
      let Vueinner = this;
      this.loading = true;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      let editedNotice = {
        data: {
          message: this.notice.notice_message
        }
      }
      axios.put(this.$store.state.apiUrl + "notice/" + Vueinner.notice.id, editedNotice, {headers}).then((r) => {
        Vueinner.showAddNotice = false;
        Vueinner.notice.notice_message = '';
        Vueinner.notice.document_id = null;
        Vueinner.$store.dispatch('toastSuccess', 'Notiz erfolgreich bearbeitet.');
        this.getNotices();
      }).catch((e) => {
        Vueinner.$store.dispatch('handleApiResponse', e.response);
      });
    },
    deleteNotice(id) {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.delete(this.$store.state.apiUrl + "notice/" + id, {headers}).then((r) => {
        this.$store.dispatch('toastSuccess', 'Notiz wurde erfolgreich gelöscht');
        this.getNotices();
      }).catch((e) => {
        this.$store.dispatch('handleApiResponse', e.response);
      });
    },
    setEditNotice(notice) {
      this.notice = notice;
      this.showAddNotice = true;
    }
  },
  created() {
    if (this.projectActive) {
      this.project = this.projectActive;
    }
  },
  mounted() {
    this.getTasks();
    this.getNotices();
  },
  watch: {
    $route(to, from) {
      if (to.params.id) {
        // check if routename is ProjectDetails
        if (to.name === 'ProjectDetails') {
          this.$store.dispatch("projectsFilter");
          if (this.projectList && this.projectList.data) {
            // next tick
            this.$nextTick(() => {
              if (this.projectList.data.length > 0) {
                this.$store.dispatch("setActiveProject", this.projectList.data.find(project => project.id === to.params.id));
                this.$nextTick(() => {
                  this.project = this.projectActive;
                });
              }
            });
          }
        }
      }
    }
  }
}
</script>

