<template>
  <button type="button" class="btn btn-xs btn-outline-success" :position="position" tooltip="Nutzer bearbeiten"
          @click="openEditJoomlaUserModal"><i class="fa-duotone fa-user-edit"></i></button>
  <Modal v-if="userEdit">
    <div class="modal show" style="display: block;">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">User bearbeiten</h5>
            <button type="button" class="btn btn-outline-primary btn-small ml-auto me-2" @click="saveUser"><i
              class="fa-duotone fa-floppy-disk"></i> Speichern
            </button>
            <button type="button" style="margin-left: 24px;" class="btn-close"
                    @click="openEditJoomlaUserModal"></button>
          </div>
          <div class="modal-body">
            <div class="row mb-2" v-if="user && Object.keys(user).length > 1">
              <div class="col-12 col-xl-2">
                <label for="userId">User ID</label>
                <input type="text" class="form-control disabled" id="userId" v-model="user.id" readonly>
              </div>
              <div class="col-12 col-xl-5">
                <label for="userName">Name</label>
                <input type="text" class="form-control" id="userName" v-model="user.name">
              </div>
              <div class="col-12 col-xl-5">
                <label for="userUsername">Username</label>
                <input type="text" class="form-control disabled" id="userUsername" readonly v-model="user.username">
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-12 col-xl-5">
                <label for="userEmail">E-Mail-Adresse</label>
                <input type="email" class="form-control disabled" id="userEmail" readonly v-model="user.email">
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-12 col-xl-12">
                <label for="blockStatus" style="min-width: 250px;">Benutzer blockieren</label>
                <div class="form-check form-check-inline ms-3">
                  <input class="form-check-input" type="radio" id="blockStatusActive" value="0" v-model="user.block">
                  <label class="form-check-label" for="blockStatusActive">Aktiv</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" id="blockStatusBlocked" value="1" v-model="user.block">
                  <label class="form-check-label" for="blockStatusBlocked">Blockiert</label>
                </div>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-12 col-xl-12">
                <label for="requireReset" style="min-width: 250px;">Benötigt Passwort-Reset</label>
                <div class="form-check form-check-inline ms-3">
                  <input class="form-check-input" type="radio" id="requireResetNo" value="0"
                         v-model="user.requireReset">
                  <label class="form-check-label" for="requireResetNo">Nein</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" id="requireResetYes" value="1"
                         v-model="user.requireReset">
                  <label class="form-check-label" for="requireResetYes">Ja</label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <ul>
                <li>Account erstellt am {{$filters.formatDateTime(user.registerDate)}}</li>
                  <li v-if="user.lastvisitDate !== '0000-00-00 00:00:00'">Letzter Besuch: {{$filters.formatDateTime(user.lastvisitDate)}}</li>
                  <li v-if="user.lastResetTime !== '0000-00-00 00:00:00'">Letzter Reset: {{$filters.formatDateTime(user.lastResetDate)}}</li>
                </ul>
                <button type="button" class="btn btn-outline-secondary btn-sm me-2" @click="resetPW()"
                        ><i class="fa-duotone fa-passport"></i> Neues Passwort setzen
                </button>
                <button type="button" class="btn btn-outline-danger btn-sm" @click="deleteUser()"><i class="fa-duotone fa-trash"></i> Benutzer löschen</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>
<script>
import axios from 'axios'

export default {
  name: 'editJoomla',
  emits: ['changed'],
  props: {
    joomlaId: {
      type: Number,
      default: 0
    },
    position: {
      type: String,
      default: 'left'
    }
  },
  data() {
    return {
      userEdit: false,
      user: {}
    }
  },
  methods: {
    openEditJoomlaUserModal() {
      this.userEdit = !this.userEdit
      this.getJoomlaUser();
    },
    getJoomlaUser() {
      if (this.joomlaId) {
        const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
        axios.get(this.$store.state.apiUrl + "user/" + this.joomlaId, {headers}).then((r) => {
          this.user = r.data.result.data
        })
      }
    },
    saveUser() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.put(this.$store.state.apiUrl + "user/" + this.user.id, this.user, {headers}).then(() => {
        this.openEditJoomlaUserModal();
        this.$store.dispatch("toastSuccess", "User gespeichert.");
      })
    },
    resetPW() {
      let VueInner = this;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.put(this.$store.state.apiUrl + "user/" + this.joomlaId + "/resetPW", {}, {headers}).then(() => {
        this.$store.dispatch("toastSuccess", "Passwort wurde per Email versendet.");

      }).catch((r) => {
        this.$store.dispatch('handleApiResponse', r.response);
      });
    },
    deleteUser(){
      let VueInner = this;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};


      this.$confirm("Benutzer löschen", 'Sicher löschen?', 'warning', {
        confirmButtonText: this.$t("globals.yes"),
        cancelButtonText: this.$t("globals.no"),
        type: 'warning',
      }).then(() => {
      axios.delete(this.$store.state.apiUrl + "user/" + this.joomlaId, {headers}).then((response) => {
        if(response.data.result.success){
          this.$store.dispatch("toastSuccess", "User gelöscht.");
          this.openEditJoomlaUserModal();
          this.$emit('changed');
        } else {
          this.$store.dispatch("toastError", "User konnte nicht gelöscht werden.");
        }
      }).catch((r) => {
        this.$store.dispatch('handleApiResponse', r.response);
      });
      }).catch(() => {
        // on cancel
        console.log('canceled');
      });
    }
  },
  mounted() {
  }
}
</script>
<style lang="scss">
input.disabled{
  color: grey;
}
.swal2-container{
  z-index: 11060;
}
</style>
