<template>

  <template v-if="$route && $route.matched && $route.matched.length">


    <template v-if="$route.matched[0].name === 'Documents'">
      <button class="btn btn-outline-danger btn-sm  me-3" :tooltip="$t('delete.documents')" position="left" v-if="hasSelection && getUser && getUser.acl && getUser.acl.customers && getUser.acl.documents.delete === 'all'"
              @click="deleteSelected( 'documents');"><i class="fa-duotone fa-trash"></i></button>

    </template>
    <template v-if="$route.matched[0].name === 'customers'">
      <button class="btn btn-outline-danger btn-sm  me-3" :tooltip="$t('delete.customers')" position="left" v-if="hasSelectionC && getUser && getUser.acl && getUser.acl.customers && getUser.acl.customers.delete === 'all'"
              @click="deleteSelected( 'customers');"><i class="fa-duotone fa-trash"></i></button>

    </template>
    <template v-if="$route.matched[0].name === 'drafts'">
      <button class="btn btn-outline-danger btn-sm  me-3" :tooltip="$t('delete.drafts')" position="left" v-if="hasSelectionD"
              @click="deleteSelected( 'drafts');"><i class="fa-duotone fa-trash"></i></button>

    </template>
    <template v-if="$route.matched[0].name === 'Events'">
      <button class="btn btn-outline-danger btn-sm  me-3" :tooltip="$t('delete.events')" position="left" v-if="hasSelectionE"
              @click="deleteSelected( 'events');"><i class="fa-duotone fa-trash"></i></button>
    </template>


  </template>
</template>
  <script>

    import {mapGetters} from "vuex";

    export default {
      name: "deleteSelected",
      data() {
        return {
        };
      },
      computed:{
        ...mapGetters(['documentList', 'customerList', "draftList", "eventList", "getUser"]),
        hasSelection() {
          return this.documentList.selected && Object.keys(this.documentList.selected).some(id => this.documentList.selected[id]);
        },
        hasSelectionC(){
          return this.customerList.selected && Object.keys(this.customerList.selected).some(id => this.customerList.selected[id]);
        },
        hasSelectionD(){
          return this.draftList.selected && Object.keys(this.draftList.selected).some(id => this.draftList.selected[id]);
        },
        hasSelectionE(){
          return this.eventList.selected && Object.keys(this.eventList.selected).some(id => this.eventList.selected[id]);
        }
      },
      components: {},

      methods: {
        deleteSelected(list) {
          let VueInner = this;
          this.$confirm('Ausgewählte Einträge wirklich löschen?','Einträge löschen', "question",  {
            confirmButtonText: 'Ja',
            cancelButtonText: 'Nein',
            confirmButtonColor: '#0088cc',
            type: 'warning'
          }).then(() => {
          this.$store.commit('deleteSelected', list);
        }).catch((r) => {
            console.log(r);
          });

      }
    }}
  </script>
