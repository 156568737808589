<template>
    <ul class="navbar-nav">

      <li class="filter-item" v-for="filter in $store.state.filters.documents">
        <template v-if="filter.name ==='customer'">
          <template v-if="$store.state.filters.documents.customer.value ===''">
            <customersearch class="small" v-model="$store.state.filters.documents.customer.value" :onlyid="true"
                            @changedCustomer="updateValue()"/>
          </template>
          <template v-else>
            <button class="btn btn-outline-primary btn-sm w-100" style="margin-bottom: 12px;"
                    @click="$store.state.lists.documents.offset = 0; $store.state.filters.documents.customer.value = ''; updateValue();">
              {{ $t('reset.customerfilter') }}
            </button>
          </template>
        </template>
        <template v-else>
        <component :is="'sketchFilter'+filter.type" :type="'documents'" :filter="filter" v-if="filter"/></template>
      </li>
    </ul>
</template>
<script>
import sketchFilterdatetime from "@/filter/components/datetime.vue";
import sketchFilterinput from "@/filter/components/textinput.vue";
import sketchFilterlist from "@/filter/components/list.vue";
import sketchFiltermultilist from "@/filter/components/multilist.vue";
import customersearch from "@/filter/components/customersearch.vue";

export default {
  name: "FiltersDocuments",
  components: {
    customersearch,
    sketchFilterlist, sketchFiltermultilist, sketchFilterinput, sketchFilterdatetime
  },
  methods: {
    updateValue() {
      this.$store.dispatch('documentsFilter');
    },
  },
  watch: {
    '$store.state.filters.documents.customer.value'(newVal, oldVal) {
      if (newVal !== oldVal) {

        this.updateValue();
      }
    }
  }
};
</script>
