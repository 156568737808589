<template>
  <div class="plugin-item headline">
    <div class="pluginLineWrap dfbApprovals">
      <div class="lineitem l-date">
        <span @click="emitSort('valid_from')" :class="(localSortDirections.valid_from) ? 'active' : ''">Gültig</span>
        <i v-if="localSortDirections.valid_from === 'asc'" class="fa-duotone fa-arrow-up"></i>
        <i v-if="localSortDirections.valid_from === 'desc'" class="fa-duotone fa-arrow-down"></i>
      </div>
      <div class="lineitem l-date">
        <span @click="emitSort('crm_created_date')" :class="(localSortDirections.crm_created_date) ? 'active' : ''">Erstellt</span>
        <i v-if="localSortDirections.crm_created_date === 'asc'" class="fa-duotone fa-arrow-up"></i>
        <i v-if="localSortDirections.crm_created_date === 'desc'" class="fa-duotone fa-arrow-down"></i>
      </div>
      <div class="lineitem  l-progress l-state">
        <span @click="emitSort('progress')" :class="(localSortDirections.progress) ? 'active' : ''">Prozess</span>
        <i v-if="localSortDirections.progress === 'asc'" class="fa-duotone fa-arrow-up"></i>
        <i v-if="localSortDirections.progress === 'desc'" class="fa-duotone fa-arrow-down"></i>
      </div>
      <div class="lineitem   l-state">
        <span @click="emitSort('state')" :class="(localSortDirections.state) ? 'active' : ''">Status</span>
        <i v-if="localSortDirections.state === 'asc'" class="fa-duotone fa-arrow-up"></i>
        <i v-if="localSortDirections.state === 'desc'" class="fa-duotone fa-arrow-down"></i>
      </div>
      <div class="lineitem l-customerinfo">
        <span @click="emitSort('title')" :class="(localSortDirections.title) ? 'active' : ''">Kunde</span>
        <i v-if="localSortDirections.title === 'asc'" class="fa-duotone fa-arrow-up"></i>
        <i v-if="localSortDirections.title === 'desc'" class="fa-duotone fa-arrow-down"></i>
      </div>
      <div class="lineitem l-info">
        <span @click="emitSort('info')" :class="(localSortDirections.info) ? 'active' : ''">Info</span>
        <i v-if="localSortDirections.info === 'asc'" class="fa-duotone fa-arrow-up"></i>
        <i v-if="localSortDirections.info === 'desc'" class="fa-duotone fa-arrow-down"></i>
      </div>
      <div class="lineitem l-reviewer">
        <span @click="emitSort('reviewer')" :class="(localSortDirections.reviewer) ? 'active' : ''">überprüft von</span>
        <i v-if="localSortDirections.reviewer === 'asc'" class="fa-duotone fa-arrow-up"></i>
        <i v-if="localSortDirections.reviewer === 'desc'" class="fa-duotone fa-arrow-down"></i>
      </div>
      <div class="lineitem l-verified">
        <span @click="emitSort('verified')" :class="(localSortDirections.verified) ? 'active' : ''"> </span>
        <i v-if="localSortDirections.verified === 'asc'" class="fa-duotone fa-arrow-up"></i>
        <i v-if="localSortDirections.verified === 'desc'" class="fa-duotone fa-arrow-down"></i>
      </div>
      <div class="lineitem l-actions ml-auto">
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import {useToast} from "vue-toastification";
import axios from "axios";

export default {
  name: "Sort",
  emits: ['sort'],
  data() {
    return {
      modalContent: null,
      toast: useToast(),
      address: {},
      localSortDirections: {...this.sortDirections},
    }
  },
  props: {
    document: Object,
    sortDirections: {
      type: Object,
      required: true
    },
  },
  computed: {
    ...mapGetters(["getConfigs"])
  },
  methods: {
    emitSort(clickedType) {
      // Reset all sort directions to empty except for the clickedType
      for (let type in this.localSortDirections) {
        if (type !== clickedType) {
          this.localSortDirections[type] = '';
        }
      }

      // Toggle the clicked type's sort direction based on your logic
      if (this.localSortDirections[clickedType] === '') {
        this.localSortDirections[clickedType] = 'desc';
      } else if (this.localSortDirections[clickedType] === 'desc') {
        this.localSortDirections[clickedType] = 'asc';
      } else {
        this.localSortDirections[clickedType] = '';  // If already sorted, reset
      }

      // Emit the clicked type and direction to the parent
      this.$emit('sort', { type: clickedType, direction: this.localSortDirections[clickedType] });
    },
  },
}
</script>
<style lang="scss">
span.iconAction {
  font-size: 1rem;
  &.disabled {
    display: none;
  }
  border: 0 !important
}
</style>
