<template>
  <div class="customer-item activeCustomer" v-if="customerActive">
    <Headline :customer="customerActive"/>
    <div class="card-body">
      <div class="customerTabs">
        <ul class="tabNav">
          <template v-for="tab in tabs" :key="tab.id">


              <li @click="$router.push({name:'CustomersTabs', params:{id:customerActive.id,tab:tab.name}})"
                  :class="[(activeTab === tab.name) ? 'active' : '', tab.name]"><i class="fa-duotone" :class="tab.icon"></i><br><small>
                {{ tab.label }}</small>
              </li>

          </template>
          <li class="documents" :class="(activeTab==='documents') ? 'active' : ''" @click="$router.push({name:'CustomersTabs', params:{id:customerActive.id,tab:'documents'}})" v-if="getMenu.documents && (getUser && getUser.acl && !getUser.acl.menu || getUser.acl.menu.documents === 'yes')">
            <i class="fa-duotone fa-file" ></i><br><small>
            {{$t('globals.documents')}}</small>
          </li>
          <li class="projects" :class="(activeTab==='projects') ? 'active' : ''" @click="$router.push({name:'CustomersTabs', params:{id:customerActive.id,tab:'projects'}})" v-if="getMenu.projects && (getUser && getUser.acl && !getUser.acl.menu || getUser.acl.menu.issues === 'yes')">
            <i class="fa-duotone fa-diagram-project" ></i><br><small>
            {{$t('globals.projects')}}</small>
          </li>
          <li class="events" :class="(activeTab==='events') ? 'active' : ''" @click="$router.push({name:'CustomersTabs', params:{id:customerActive.id,tab:'events'}})" v-if="getMenu.events && (getUser && getUser.acl && !getUser.acl.menu || getUser.acl.menu.events === 'yes')">
            <i class="fa-duotone fa-calendar" ></i><br><small>
            {{$t('globals.events')}}</small>
          </li>
        </ul>
        <div class="customerContent">
          <Component :is="activeTab"    />
        </div>
        <div class="card">
          <div class="card-body">
            <UploadComponent :compact="true" :customerId="customerActive.id" /></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Notes from "@/routing/core/Customers/Notes";
import Activity from "@/routing/core/Customers/Activity";
import Contact from "@/routing/core/Customers/Contact";
import Edit from "@/routing/core/Customers/Edit";
import Documents from "@/routing/core/Customers/Documents";
import Mails from "@/routing/core/Customers/Mails";
import Headline from "@/routing/core/Customers/Layouts/Headline";
import Infos from "@/routing/core/Customers/Infos";
import {mapGetters} from "vuex";
import Editor from "@/components/Editor2";
import Crmplugins from "@/routing/core/Customers/CrmPlugins";
import Projects from "@/routing/core/Customers/Projects";
import Events from "@/routing/core/Customers/Events";
import UploadComponent from "@/directives/Upload/UploadComponent.vue";

export default {
  name: "CustomerDetails",
  components: {
    UploadComponent,
    Headline,
    Contact,
    Documents,
    Editor,
    Mails,
    Notes,
    Activity,
    Events,
    Edit,
    Infos,
    Crmplugins,
    Projects
  },
  computed: {
    ...mapGetters(["getPlugins", "getEmployees", "customerActive", "getMenu", "getUser"])
  },
  methods: {},
  data() {
    return {
      activeTab: 'contact',
      tabs: [
        {name: 'contact', label: this.$t('globals.contact'), icon: 'fa-user'},
        {name: 'notes', label: this.$t('globals.notes'), icon: 'fa-sticky-note'},
        {name: 'infos', label: this.$t('globals.info'), icon: 'fa-info-circle'},
        {name: 'crmplugins', label: this.$t('globals.forms'), icon: 'fa-puzzle-piece'}
      ]
    }
  },
  created() {
    if (this.$route.params.tab) {
      this.activeTab = this.$route.params.tab;
    } else {
      this.activeTab = 'contact';
    }
    if (this.getPlugins.form && this.getPlugins.form.length === 0) {
      this.tabs.splice(5, 1);
    }
  },
  watch: {
    $route(to) {
      if (to.params.tab) {
        this.activeTab = to.params.tab;
      } else {
        this.activeTab = 'contact';
      }
    }

  }
};
</script>
