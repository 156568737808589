<template>
  <div class="card">
    <div class="card-header">
      <div class="float-end" style="position: relative; z-index: 1;">
        <button @click="saveDesign" class="btn btn-outline-primary btn-sm me-3"><i class="fa-duotone fa-floppy-disk"></i> Vorlage speichern</button>
        <button @click="exportHtml" class="btn btn-outline-primary btn-sm"><i class="fa-duotone fa-code"></i> exportieren</button></div>
      <h6 class="card-title">E-Mail Template</h6>

    </div>
    <div class="card-body">
      <EmailEditor
        ref="emailEditor"
        :min-height="minHeight"
        :locale="locale"
        :project-id="projectId"
        :options="editorOptions"
        v-on:load="editorLoaded"
        v-on:ready="editorReady"
      />
    </div>
    <div class="exportDataPreview">

      <div class="previewPane" style="background: #333;padding: 24px;" v-if="exportData && exportData.html">
    <pre style="color: #fff;font-size: 13px;">
      {{ exportData.html }}
    </pre>
      </div>
      <div class="settingsPane" v-if="exportData && exportData.design">

          <pre>
          {{ exportData.design }}
        </pre>
      </div>

    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import axios from "axios";
import {EmailEditor} from 'vue-email-editor';
import EditorHtml from "@/components/EditorHtml.vue";

export default {
  name: "EmailTemplate",
  emits: ['refresh-project-list'],
  components: {
    EmailEditor, EditorHtml
  },
  data() {
    return {
      emailTemplate: {
        title: '',
        description: '',
        c_firstname: '',
        c_lastname: '',
        c_company: '',
        crm_created_date: '',
        crm_modified_date: '',
      },
      taskList: {
        data: []
      },
      notice: {
        document_id: null,
        notice_message: ''
      },
      notices: [],
      showAddNotice: false,
      minHeight: '80vh',
      locale: 'de',
      projectId: 1234, // replace with your project id
      editorOptions: {
        locale: "de",
        appearance: {
          theme: "light",
        },
        tools: {
          text: {
            properties: {
              fontFamily: {
                value: "Arial, sans-serif", // Set a default font
                options: [
                  {label: "Arial", value: "Arial, sans-serif"},
                  {label: "Helvetica", value: "Helvetica, sans-serif"},
                  {label: "Times New Roman", value: "'Times New Roman', serif"},
                  {label: "Georgia", value: "Georgia, serif"},
                  {label: "Courier New", value: "'Courier New', monospace"},
                  {label: "Verdana", value: "Verdana, sans-serif"}
                ],
              },
            },
          },
        },
        editor: {
          fonts: {
            showDefaultFonts: true,
            customFonts: [
              {label: "Arial", value: "Arial, sans-serif"},
              {label: "Helvetica", value: "Helvetica, sans-serif"},
              {label: "Times New Roman", value: "'Times New Roman', serif"},
              {label: "Georgia", value: "Georgia, serif"},
              {label: "Courier New", value: "'Courier New', monospace"},
              {label: "Verdana", value: "Verdana, sans-serif"}
            ],
          },
        },
      },
      appearance: {
        theme: 'dark',
        panels: {
          tools: {
            dock: 'right',
          },
        },
      },
      exportData: {},
    }
  },
  computed: {
    ...mapGetters(["projectActive", "getEmployees", "modalsList", "getPlugins", "projectList", "getUser"]),
    countNotices() {
      return this.notices.length;
    }
  },
  methods: {
    editorLoaded() {
      console.log('editorLoaded');
      setTimeout(() => {
        let iframe = document.querySelector(".unlayer-editor iframe");
        if (iframe) {
          iframe.style.height = "80vh"; // Adjust as needed
        }
      }, 500);
    },
    // called when the editor has finished loading
    editorReady() {
      // Register a custom image upload function
      this.$refs.emailEditor.editor.registerCallback('image', (file, done) => {
        this.uploadImage(file, done);
      });
      this.$refs.emailEditor.editor.loadDesign({
        locale: "de", // Set the correct locale
      });
      this.$refs.emailEditor.editor.setMergeTags({
        fonts: [
          {label: "Arial", value: "Arial, sans-serif"},
          {label: "Helvetica", value: "Helvetica, sans-serif"},
          {label: "Times New Roman", value: "'Times New Roman', serif"},
          {label: "Georgia", value: "Georgia, serif"},
          {label: "Courier New", value: "'Courier New', monospace"},
          {label: "Verdana", value: "Verdana, sans-serif"}
        ],
      });
    },
    saveDesign() {
      this.$refs.emailEditor.editor.saveDesign((design) => {
        console.log('saveDesign', design);
      });
    },
    exportHtml() {
      this.$refs.emailEditor.editor.exportHtml((data) => {
        this.exportData = data;
      });
    },
    async uploadImage(file, done) {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      let formData = new FormData();
      formData.append("image", file);
      try {
        let response = await axios.post('/images/upload', formData, {headers});
        if (response.data.url) {
          done({progress: 100, url: response.data.url}); // Return image URL
        } else {
          done({progress: 0, url: null, error: "Upload failed"});
        }
      } catch (error) {
        console.error("Image upload error:", error);
        done({progress: 0, url: null, error: "Upload failed"});
      }
    }
  }
}
</script>
