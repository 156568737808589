<template>
  <div class="card-body draft-list">
    <p class="text-sm text-secondary" v-if="!getConfigs || getConfigs && getConfigs.countries && getConfigs.countries.length === 0">Es wurden keine Vorlagen
      gefunden.</p>
    <Sort @sort="resortOrder($event)"/>
    <div class="draft-item" v-for="draft in getConfigs.countries"
         :key="draft.id">
      <Headline :draft="draft"/>
    </div>
    <router-link to="/config/basic" class="mt-5 mb-5" style="display: block;font-size: 14px;"><i class="fa-duotone fa-chevron-left"></i> {{$t('nav.back')}}</router-link>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import Headline from "@/routing/core/Config/Basic/CountryConfigs/Layouts/Headline.vue";
import Pagination from "@/filter/components/pagination.vue";
import $ from "jquery";
import Sort from "@/routing/core/Config/Basic/CountryConfigs/Layouts/Sort.vue";

export default {
  name: "DraftSearch",
  data() {
    return {
      countries: [],
    }
  },
  computed: {
    ...mapGetters(["getEmployees", "draftList", "getConfigs", "draftActive"])
  },
  components: {
    Sort,
    Pagination,
    Headline,
  },
  methods: {
    getCountry() {
      this.$store.dispatch("countryFilter");
    },
    resortOrder(payload) {
      if (payload.direction) {
        this.$store.state.filters.customers.ordering.value = payload.type;
        this.$store.state.filters.customers.direction.value = payload.direction;
      } else {
        this.$store.state.filters.customers.ordering.value = payload.type
      }
      this.getCountry();
    },
    scrollToTop() {
      $("html, body").animate({scrollTop: 0});
    },
  },
  created() {
  },
  watch: {
    $route(to, from) {
    },
    draftActive() {
    }
  }
};
</script>
